<template>
  <!-- <VueDocPreview url="resources/images/elenewsletter.docx" type="office" /> -->
  <!-- <iframe style="width: 100%" src="https://docs.google.com/gview?url=http://206.189.95.69:3001/uploads/privacynotice.docx&embedded=true"></iframe> -->
  <!-- <iframe style="width: 100%; height: 100vh;" src='https://docs.google.com/viewer?url=http://206.189.95.69:3001/uploads/privacynotice.docx&embedded=true' frameborder='0'></iframe> -->
  <div v-html="compiledHtml">
  </div>
</template>

<script>
import apiClient from '@/services/axios'
// import VueDocPreview from 'vue-doc-preview'
export default {
  components: {
    // VueDocPreview,
  },
  created () {
    this.loadFile()
  },
  computed: {
    compiledHtml: function() {
      return this.input
    },
  },
  data () {
    return {
      fileName: 'terms.html',
      input: '',
    }
  },
  methods: {
    async loadFile() {
      const res = await apiClient.get('/uploads/privacynotice.htm')
      this.input = res.data
    },
  },
}
</script>
